<template>
  <div class="muzoo-problembox">
    <vue-markdown :source="probDescr"> </vue-markdown>
    <div class="problem-answer-container">
      <div :class="answerClass">
        <h3>Your Answer: {{ correctLabel }}</h3>
        <div class="problem-answer-box">
          <div>
            <!--                        <prism-editor class="prism-editor-wrapper" v-model="stuSoln" :language="lang"-->
            <!--                                      :line-numbers=true-->
            <!--                        ></prism-editor>-->
            <codemirror v-model="stuSoln" :options="cmOptions"></codemirror>
          </div>
          <button v-on:click="submitCode" class="md-button" :disabled="running">
            {{ submitBtnDisplay }}
          </button>
        </div>
        <div v-if="testResultsReceived" :class="answerClass">
          <h3>Test Results:</h3>
          <table class="problem-results">
            <tr>
              <th>Test#</th>
              <th>Outcome</th>
              <th>Info</th>
              <th>Hint</th>
            </tr>

            <tr v-for="(res, index) in testResultsInfo" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <div :class="res.outcome"></div>
              </td>
              <td>{{ res.info }}</td>
              <td>{{ res.details }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// const { URL } = require('url')
import Vue from "vue";
import VueMarkdown from "vue-markdown";
import axios from "axios";
import { codemirror } from "vue-codemirror";

// // prism
// import "prismjs";
// import "prismjs/components/prism-python";

// codemirror
import "codemirror/mode/python/python.js";

axios.interceptors.request.use(
  (config) => {
    console.log("Adding bearer token");
    if (Vue.prototype.$kc && Vue.prototype.$kc.authenticated) {
      const token = Vue.prototype.$kc.token;
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  name: "MZProblemBox",
  props: {
    lang: { type: String, default: "python" },
    appurl: { type: String, required: true },
    problemid: { type: String, required: true },
  },
  components: {
    VueMarkdown,
    codemirror,
    // PrismEditor,
  },
  computed: {
    answerClass() {
      if (this.solved) return "problem-solved";
      if (!this.solved && this.testResultsReceived)
        return "problem-solve-failed";
      return "";
    },
    correctLabel() {
      return this.solved ? "[Correctly Solved]" : "";
    },
    langClassName() {
      return `language-${this.lang}`;
    },
    submitBtnDisplay() {
      return this.running ? "Running" : "Submit";
    },
  },
  data() {
    return {
      probDescr: "",
      stuSoln: "# Put your answer here\n",
      solved: false,
      running: false,
      testResultsReceived: false,
      testResultsInfo: [],
      cmOptions: {
        tabSize: 4,
        indentUnit: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: "text/x-python",
        theme: "elegant",
      },
      // testResultsReceived: true,
      // testResultsInfo: [
      //     {title: "1", case_description: "foo(2, 4) == x", result: false},
      //     {title: "2", case_description: "foo(5, 7) == 2", result: true},
      //     {title: "3", case_description: "foo(1, 2) == y", result: false},
      // ]
    };
  },
  created() {
    let appURL = this.appurl + (this.appurl.endsWith("/") ? "" : "/");
    let sourceURL = new URL(`${appURL}exercise/${this.problemid}/`);

    axios
      .get(sourceURL)
      .then((res) => {
        if (res.status == 200) this.probDescr = res.data["detail"];
        else throw "meh problem descr";
      })
      .catch(() => {
        let msg = "## Failed to load the problem. Please try again.";
        console.log(msg);
        this.probDescr = msg;
      });

    let statusURL = new URL(`${appURL}exercise/${this.problemid}/status`);
    axios
      .get(statusURL)
      .then((res) => {
        if (res.status != 200) throw "meh /status";

        let data = res.data;
        let latestSubmission = data && data["latest_submission"];
        // solved if any prior submission solved the task
        this.solved = data.solved == true;

        // populate the code with the latest submission
        if (latestSubmission) this.stuSoln = latestSubmission["source_code"];
      })
      .catch((err) => {
        let msg = "## Failed to load status. Please try again.";
        console.log(msg);
        console.log(err);
      });
  },
  methods: {
    submitCode() {
      this.running = true;
      let appURL = this.appurl + (this.appurl.endsWith("/") ? "" : "/");
      let sourceURL = new URL(`${appURL}exercise/${this.problemid}/`);
      axios
        .post(sourceURL, { code: this.stuSoln })
        .then((res) => {
          if (res.status != 200) throw `Http Code ${res.status}`;
          let data = res.data;

          this.solved = data.solved == true;
          this.testResultsReceived = true;
          // parse backend results
          let runResults = JSON.parse(data.results);
          let testResults = runResults && runResults["tests"];
          if (testResults) {
            testResults.forEach((element) => {
              element.info = "";
              element.details = element?.message;
              if (element.error_name === "AssertionError") {
                element.info = "Incorrect Output";
              } else {
                element.info = "Code Issue";
              }
            });
            this.testResultsInfo = testResults;
          }
        })
        .catch((err) => {
          alert("Code submission/execution error: " + err);
        })
        .finally(() => {
          this.running = false;
        });
    },
  },
};
</script>

<style scoped>
/* @import "~prismjs/themes/prism.css"; */
@import "~codemirror/lib/codemirror.css";
@import "~codemirror/theme/elegant.css";

@import url("https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.5.1/katex.min.css");

.muzoo-problembox {
  /* margin: 1rem;
        padding: 20px; */
}

.problem-solved {
  background: lightgreen;
}

.problem-solve-failed {
  background: orange;
}

.problem-answer-box {
  margin: 1em;
}

.problem-answer-container h3 {
  margin: 0.5rem;
}
.problem-answer-container {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
/* div.prism-editor-wrapper {
  height: 250px;
  overflow-y: auto;
} */
.problem-results th,
td {
  padding-right: 1em;
  padding-left: 1em;
}

.problem-results {
  margin: 0.5rem;
}
.problem-results tr:nth-child(even) {
  background: #ccc;
}
.problem-results tr:nth-child(odd) {
  background: #fff;
}

button:disabled {
  background: #aaa;
  pointer-events: none;
}

.passed {
  text-align: center;
}

.passed::before {
  content: "\2713";
  font-size: 25px;
  color: green;
}

.failed {
  text-align: center;
}

.failed::before {
  content: "\2716";
  font-size: 25px;
  color: red;
}
</style>